/**
* Global Javascript
*/
// Namespace and ready event
(function ($) {
	$(function () {
	
		/**
		 * Navigation 
		 *
		 * @note This navigation connects visually the <code>#menu</code> with
		 *       the <code>.featured-projects</code> list.
		 *       The trick is done here via the class dot and the id hash, as
		 *       e.g. #women is the item in the featured-project list, whereas
		 *       .women is the menu item.
		 */
		$('.overview-page').find('#menu a').hover(
			function() {
				var project = $(this).closest('li').attr('class');
				$('#' + project).addClass('over');
			},
			function() {
				var project = $(this).closest('li').attr('class');
				$('#' + project).removeClass('over');
			}
		)
		.end()
		.find('.featured-projects a').hover(
			function() {
				var menu = $(this).closest('li').attr('id');
				$('.' + menu).find('a').addClass('over');
			},
			function() {
				var menu = $(this).closest('li').attr('id');
				$('.' + menu).find('a').removeClass('over');
			}
		);
		
		/**
		 * Gallery lightbox
		 */
		var lang = $('html').attr('lang');
		var popoutGallery = function() {
			
			$(".gallery").find("a").colorbox({
				width:"80%", 
				height:"80%",
				title: function() {
					return $(this).find('img').attr('title');
				},
				next: function() {
					return lang === 'de' ? 'Nächstes' : 'next';
				},
				previous: function() {
					return lang === 'de' ? 'Vorheriges' : 'previous';
				},
				current: function() {
					var curDe = "{current} von {total}",
						curEn = "{current} of {total}";
					return lang === 'de' ? curDe : curEn;
				},
				close: function() {
					return lang === 'de' ? 'Schließen' : 'close';
				}
			});
			
		};
		popoutGallery();

		/**
		 * Resize
		 */		
		$(window).resize(function(){
			var elem = $(this);

			// elem.width();
			// elem.height();
			
						
		});
		$(window).resize();
		
		/**
		 * Nospam
		 */
		$('a.email').nospam({ 
			filterLevel: 'low',
			replaceText: true,
			nestedElementSelector: '.fallback'
		});
	
	});
}(jQuery));