/**
* Global Javascript
*/

/**
* Global function to create namespaces
*/
var namespace = function (ns, root) {
	var nsParts = ns.split('.'),
		i;
	root = root || window;
	for (i = 0; i < nsParts.length; i++) {
		if (typeof root[nsParts[i]] === 'undefined') {
			root[nsParts[i]] = {};
		}
		root = root[nsParts[i]];
	}
};


/**
* supplant() does variable substitution on the string. It scans through the string looking for 
* expressions enclosed in { } braces. If an expression is found, use it as a key on the object, 
* and if the key has a string value or number value, it is substituted for the bracket expression 
* and it repeats.
*
* Written by Douglas Crockford
* http://www.crockford.com/
*/
String.prototype.supplant = function (o) {
	return this.replace(
		/{([^{}]*)}/g, 
		function (a, b) {
			var r = o[b];
			return typeof r === 'string' || typeof r === 'number' ? r : a;
		}
	);
};



/**
 * Firebugx - firebuglight
 * 
 * @note this prevents accidentially errors in none-ff browsers
 */ 
// @workaround prevent ie-oldies throwing js errors with forgotten console.log
if ($.browser.msie && $.browser.version.substr(0,1) < 9) {
	if (!('console' in window) || !('firebug' in console)) {
	    var names = ['log', 'debug', 'info', 'warn', 'error', 'assert', 'dir', 'dirxml',
	    'group', 'groupEnd', 'time', 'timeEnd', 'count', 'trace', 'profile', 'profileEnd'];

	    window.console = {};
	    for (var i = 0, len = names.length; i < len; ++i) {
	        window.console[names[i]] = function () {};
	    }
	}
}

/**
 * setting cookie
 */
function setCookie(name,value,days) {
    if (days) {
        var date = new Date();
        date.setTime(date.getTime()+(days*24*60*60*1000));
        var expires = "; expires="+date.toGMTString();
    }
    else var expires = "";
    document.cookie = name+"="+value+expires+"; path=/";
}

/**
 * reading cookie
 */
function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}
